import { store } from '@/store'
import _ from 'lodash'
import { getGlobalUserType , getStorageUserType} from '@/utils/util'
import { formDataTrans, requestToken } from '../../../api/manage'

export async function getDeviceDataIntable(userType: number, orgId: any) {
  // userType:平台管理员0 admin 、机构管理员1 ORG、机构操作员2 Operater
  const response = await requestToken({
    url: `/api/v1/devices/${getGlobalUserType(userType)}/devicesOfOrg/${orgId}`,
    method: 'get',
  })
  store.commit('setDeviceData', response)
  return response
}

export async function getTypeDeviceData(userType: number, orgId: any) {
  // userType:平台管理员0 admin 、机构管理员1 ORG、机构操作员2 Operater
  const response = await requestToken({
    url: `/api/v1/devices/${getGlobalUserType(userType)}/getDevicesBindInfo/${orgId}`,
    method: 'get',
  })

  store.commit('setDeviceData', response)
  return response
}

// Multi delete device Info
// userType:平台管理员0 admin 、机构管理员1 ORG、机构操作员2 Operater
export function deleteAll(userType: number, rowIDSelect: BigInteger[]) {
  return requestToken({
    url: `/api/v1/devices/${getGlobalUserType(userType)}`,
    method: 'delete',
    data: rowIDSelect,
  })
}
// Single delete device Info
// userType:平台管理员0 admin 、机构管理员1 ORG、机构操作员2 Operater
export function deleteOne(userType: number, rowIDSelect: number) {
  const id = rowIDSelect
  requestToken({
    url: `/api/v1/devices/${getGlobalUserType(userType)}/${id}`,
    method: 'delete',
  })
}
export function updateDevice(devicefile: any, orgId: any) {
  requestToken({
    url: `/api/v1/devices/updateDevice/${orgId}`,
    method: 'put',
    data: devicefile,
  })
}
export async function multiUpdateDevice(devicefile: any, orgId: any) {
  return requestToken({
    url: `/api/v1/devices/multiUpdateDevice/${orgId}`,
    method: 'put',
    data: devicefile,
  })
}
// csv file upload Device  TODO
export async function csvFileUpload(uploadSeq: any, uploadFile: any, orgId: any) {
  const formData = formDataTrans({ uploadFile, uploadSeq, orgId })
  const response: any = await requestToken({
    headers: {
      'Content-Type': 'multipart/form-data;',
    },
    url: `/api/v1/devices/uploadCsv`,
    method: 'post',
    data: formData,
  })
  store.state.main.messagePro = response
}
export function tableAddDeviceInfo(userType: number, data: any) {
  requestToken({
    url: `/api/v1/devices/${getGlobalUserType(userType)}`,
    method: 'post',
    data,
  })
}

export async function getDeviceSettingsInfo(deviceId: number) {
  const response = await requestToken({
    url: `/api/v1/devices/getDeviceSettingsInfo/${deviceId}`,
    method: 'get',
  })

  return response
}

export async function getDeviceBindInfo(userType: number, orgId: any) {
  const response = await requestToken({
    // url: `/api/v1/devices/${getGlobalUserType(userType)}/getDevicesBindInfo/${orgId}`,
    url: `/api/v1/devices/getBoundDevicesOfOrg/${orgId}`,
    method: 'get',
  })
  store.state.main.deviceHardWare.deviceData = response as any
  return response
}

export async function creatDeviceInfo(userType: number, data: any) {
  let response: any
  response = await requestToken({
    url: `/api/v1/devices/${getGlobalUserType(userType)}`,
    method: 'post',
    data,
  })
  store.state.main.deviceHardWare.deviceData = response
  return response
}

export function multiBindDevicePerson(orgId: any, deviceid: any, personIds: any, orgIs: any) {
  requestToken({
    url: `/api/v1/devices/multiBindDevicePersons/${orgId}/${deviceid}`,
    method: 'put',
    data: { person_ids: personIds, org_ids: orgIs },
  })
}
export function multiOwnDeviceOrgs(userType: number, deviceId: any, orgIs: any) {
  requestToken({
    url: `/api/v1/devices/${getGlobalUserType(userType)}/multiOwnDeviceOrgs/${deviceId}`,
    method: 'put',
    data: { org_ids: orgIs },
  })
}

export async function getDeviceWithOrgan(userType: number, deviceId: any) {
  let response: any
  response = await requestToken({
    url: `/api/v1/devices/${getGlobalUserType(userType)}/getDeviceWithOrgan/${deviceId}`,
    method: 'get',
  })
  store.state.main.deviceHardWare.deviceData = response
}

export async function getDeviceWithPersons(userType: number, deviceId: any) {
  let response: any
  response = await requestToken({
    url: `/api/v1/devices/${getGlobalUserType(userType)}/getDeviceWithPersons/${deviceId}`,
    method: 'get',
  })
  store.state.main.deviceHardWare.deviceData = response
}

export async function getDeviceWithPersonsBlacklist(deviceId: any) {
  let response: any
  response = await requestToken({
    url: `/api/v1/devices/getBlackList/${deviceId}`,
    method: 'get',
  })
  store.state.main.deviceHardWare.blacklist = response
}

export async function addBlacklist(orgId: any, deviceId: any, personIds: any) {
  requestToken({
    url: `/api/v1/devices/addBlackList/${orgId}/${deviceId}`,
    method: 'post',
    data: { person_ids: personIds},
  })
}

// 下载设备的csv数据文件
export async function downloadDataCsv(transParams : any , orgId : any) {
  const response : any = requestToken({
    headers: {
      Accept: 'application/json',
    },
    url: `/api/v1/devices/${getStorageUserType()}/exportCsv/${orgId}`,
    method: 'get',
    params: transParams,
    responseType: 'blob',
  })
  store.state.main.messagePro = response
}

// 读取设备，返回数组（管理员）
export function readBySuper() {
  return requestToken({
    url: '/api/v1/devices/superuser',
    method: 'get',
  })
}
// 读取设备，返回数组（企业）
export function readByORG() {
  return requestToken({
    url: '/api/v1/devices/manager',
    method: 'get',
  })
}
// 读取设备，返回数组（操作员）
export function readByOPT() {
  return requestToken({
    url: '/api/v1/devices/operator',
    method: 'get',
  })
}

// 绑定设备与人员（管理员）
export function bindBySuper(userOrgId, data) {
  return requestToken({
    url: `/api/v1/devices/superuser/bind_device_person/${userOrgId}`,
    method: 'post',
    data,
  })
}

// 绑定设备与人员（企业）
export function bindByORG(userOrgId, data) {
  return requestToken({
    url: `/api/v1/devices/manager/bind_device_person/${userOrgId}`,
    method: 'post',
    data,
  })
}

// 绑定设备与人员（操作员）
export function bindByOPT(userOrgId, data) {
  return requestToken({
    url: `/api/v1/devices/operator/bind_device_person/${userOrgId}`,
    method: 'post',
    data,
  })
}

// 查询设备入参调整
function handelTransParams(filters, pageNum, showCount, sortField, sortOrder) {
  let transParams: any = {}
  let theOrgId: string = ''
  if (filters) {
    if (filters.org_id) {
      theOrgId = filters.org_id || filters.org_id[0]
      filters = _.omit(filters, 'org_id')
    }
    transParams = {
      page: pageNum,
      limit: showCount,
      sortField,
      sortOrder,
      filters,
      org_id: theOrgId,
    }
  }
  return transParams
}
